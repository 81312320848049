<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="5">
                  <v-autocomplete
                    v-model="editedItem.agent_id"
                    item-text="nom"
                    :item-value="'id'"
                    :items="agents"
                    label="Agent"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Agent obligatoire']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              ref="save"
              color="blue darken-1"
              text
              @click="save"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_INVENT_AGENT from "../graphql/Inventaire/CREATE_INVENT_AGENT.gql";
import UPDATE_INVENT_AGENT from "../graphql/Inventaire/UPDATE_INVENT_AGENT.gql";

export default {
  components: {},
  name: "invdetailform",
  props: {
    item: Object,
    items: Array,
    invent: Object,
    readonly: Boolean,
    showForm: Boolean,
    equipe_id: Number,
    agents_list: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
  }),

  computed: {
    agents() {
      let filteredX = [];
      if (this.agents_list) {
        let yFilter = this.items.map((itemY) => {
          return itemY.agent_id;
        });

        yFilter = yFilter.filter((elm) => elm != this.item.agent_id);
        filteredX = this.agents_list.filter(
          (itemX) => !yFilter.includes(itemX.id)
        );
        filteredX = filteredX.filter((elm) => elm.id != this.invent.pilot_id);
      }
      return filteredX;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Ajout Agent  "
          : "Agent : ".concat(this.item.nom);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let i = this.agents_list.findIndex(
          (elm) => elm.id == this.editedItem.agent_id
        );
        if (i >= 0) {
          this.editedItem.matricule = this.agents_list[i].matricule;
          this.editedItem.nom = this.agents_list[i].nom;
          this.editedItem.fonction = this.agents_list[i].fonction;
        }

        let v;
        if (this.editedItem.id > 0) {
          v = {
            invagent: {
              id: this.editedItem.id,
              agent_id: this.editedItem.agent_id,
              write_uid: this.$store.state.me.id,
            },
          };

          this.maj(UPDATE_INVENT_AGENT, v);
          this.items.splice(this.editedItem.index, 1, this.editedItem);
        } else {
          v = {
            invagent: {
              equipe_id: this.equipe_id,
              agent_id: this.editedItem.agent_id,
              write_uid: this.$store.state.me.id,
              create_uid: this.$store.state.me.id,
            },
          };

          let r = await this.maj(CREATE_INVENT_AGENT, v);
          if (r) this.editedItem.id = r.createInvAgent.id;
          this.items.push(this.editedItem);
          this.$emit("add");
        }
      }
    },
  },
};
</script>
